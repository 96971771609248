
import { AttachmentsScanFileTypes, IAttachment, IScan, scans } from "@/api";
import Vue from "vue";
import { Actions, ScansActions } from "@/store/models";
import EditableLabel from "@/components/common/EditableLabel.vue";
import { bufferToImage, getDate } from "@/utils";

export default Vue.extend({
  name: "Projects",
  components: {
    EditableLabel,
  },
  data() {
    return {
      id: this.$route.params.scanId,
      scan: {} as IScan,
      selected: "",
      photo: "",
      toolbar: true
    };
  },
  computed: {
    details: function (): any {
      const s = this.scan;
      return {
        autosaved: `Autosaved ${getDate(
          s.updated_datetime || s.created_datetime
        )} `,
        userId: s.updated_by || s.created_by,
        equipmentType: s.device?.device_name,
        serial: s.device?.device_serial_number,
      };
    },
  },
  created() {
    this.getScan();
  },
  methods: {
    async getScan() {
      try {
        this.scan = (await scans.get(Number(this.$route.params.scanId))).data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async updateDescription(description: string) {
      await scans.update(this.scan.id, description);
      this.getScan();
    },
    async remove() {
      if (confirm("Are you sure to delete this scan?")) {
        await this.$store.dispatch(ScansActions.Delete, [this.scan.id]);
        this.$router.go(-1);
      }
    },
    is2DScan(attachment: IAttachment) {
      return attachment.file_type.name === AttachmentsScanFileTypes.TwoDScan;
    },
  },
  watch: {
    selected() {
      if (!this.selected) return;
      const id = this.selected.split("-")[1];
      const item = this.scan.attachments.find((a) => a.id.toString() == id);
      if (item?.cloud_file_icon) {
        this.photo = item.cloud_file_icon?.data
          ? bufferToImage(item.cloud_file_icon.data)
          : "";
      } else {
        this.photo = "";
      }
    },
  },
});
